import Layout from "@/layout";

export const macroRouter = {
  path: '/macro',
  component: Layout,
  hidden: false,
  icon:'user',
  name:"全球宏观指标",
  meta:{icon: 'flag-global', menu: true, title: "全球宏观指标"},
  children: [{
    path:"us",
    name: '美国',
    meta: {menu: true, title: "美国", icon: "flag-us"},
    component: {render: c => c("router-view")},
    children:[
      {
        path:"gpd",
        name: '美国',
        component: (resolve) => require(['@/views/macro/us/gdp'], resolve),
        meta: {menu: true, title: "GDP"},
      },
      {
        path:"gpd2",
        name: '利率',
        meta: {menu: true, title: "利率"},
      },{
        path:"gpd3",
        name: "物价水平（CPI）",
        meta: {menu: true, title: "物价水平（CPI）"},
      }, {
        path:"gpd4",
        name: "劳动力市场",
        meta: {menu: true, title: "劳动力市场"},
      }, {
        path:"gpd5",
        name: "贸易状况",
        meta: {menu: true, title: "贸易状况"},
      }, {
        path:"gpd6",
        name :"产业指标",
        meta: {menu: true, title: "贸易状况"},
      }
    ]
  },
    {
      path: 'eu',
      component:  {render: c => c("router-view")},
      name: 'eu',
      icon:'user',
      meta: {icon: 'flag-eu', menu: true, title: "欧盟"},
      children:[
        {
          path:"nation-economy",
          name: "nationEconomy",
          component: (resolve) => require(['@/views/macro/eur/eurNationalEconomy'], resolve),
          meta: {menu: true, title: "国民经济"},
        },
        {
          path:"price",
          component: (resolve) => require(['@/views/macro/eur/eurPriceEconomy'], resolve),
          meta: {menu: true, title: "物价水平"},
        },
        {
          path:"trade",
          name: 'trade',
          component: (resolve) => require(['@/views/macro/eur/eurTradeEconomy'], resolve),
          meta: {menu: true, title: "贸易状况"},
        },
        {
          path:"industry",
          name: 'industry',
          component: (resolve) => require(['@/views/macro/eur/eurIndustryEconomy'], resolve),
          meta: {menu: true, title: "产业指标"},
        },
        {
          path:"employment",
          name: 'employment',
          component: (resolve) => require(['@/views/macro/eur/eurEmployment'], resolve),
          meta: {menu: true, title: "劳动力市场"},
        }
      ]
    },

    {
      path: 'ger',
      component:  {render: c => c("router-view")},
      name: 'ger',
      icon:'user',
      meta: {icon: 'flag-de', menu: true, title: "德国"},
      children:[
        {
          path:"nation-economy",
          name: "gerNationEconomy",
          component: (resolve) => require(['@/views/macro/ger/gerNationalEconomy'], resolve),
          meta: {menu: true, title: "德国国民经济"},
        },
        {
          path:"trade",
          name: 'trade',
          component: (resolve) => require(['@/views/macro/eur/eurTradeEconomy'], resolve),
          meta: {menu: true, title: "贸易状况"},
        },
        {
          path:"industry",
          name: 'industry',
          component: (resolve) => require(['@/views/macro/eur/eurIndustryEconomy'], resolve),
          meta: {menu: true, title: "产业指标"},
        },

      ]
    },

    {
      path: 'gb',
      component: (resolve) => require(['@/views/user/profile/index'], resolve),
      name: 'gb',
      icon:'user',
      meta: {icon: 'flag-gb', menu: true, title: "英国"}
    },
    {
      path: 'jp',
      component: (resolve) => require(['@/views/user/profile/index'], resolve),
      name: 'jp',
      icon:'user',
      meta: {icon: 'flag-jp', menu: true, title: "日本"}
    },
    {
      path: 'au',
      component: (resolve) => require(['@/views/user/profile/index'], resolve),
      name: 'au',
      icon:'user',
      meta: {icon: 'flag-au', menu: true, title: "澳大利亚"}
    },
    {
      path: 'ca',
      component: (resolve) => require(['@/views/user/profile/index'], resolve),
      name: 'ca',
      icon:'user',
      meta: { icon: 'flag-ca', menu: true, title: "加拿大"}
    },
    {
      path: 'ch',
      component: (resolve) => require(['@/views/user/profile/index'], resolve),
      name: 'ch',
      icon:'user',
      meta: {icon: 'flag-ch', menu: true, title: "瑞士"}
    }
  ],
}
