<template>
<!--  <div style="margin-left: 12px">-->
    <span :style="{'margin-left': '12px', 'padding-left': '5px','padding-right': '5px', 'background-color': backgroundColor2, color:'#FFFFFF', 'border-radius': '4px', 'font-size': '14px'}">{{ title }}</span>
<!--  </div>-->
</template>

<script>
export default {
  name: "index",
  props:{
    category:String,
  },
  data(){
    return {
      "title" : "",
      backgroundColor2 : 'red',
      textColor2:'blue'
    }
  },
  mounted() {
    if (this.category === "stock") {
      this.title = "股票";
      this.backgroundColor2 = '#F79633';
      this.textColor2 = "#FFFFFF";
    } else if (this.category ==="index") {
      this.title = "指数";
      this.backgroundColor2 = '#7A7F91';
      this.textColor2 = 'blue'
    } else if (this.category ==="fund") {
      this.title = "基金";
      this.backgroundColor2 = '#F35135';
      this.textColor2 = '#FFFFFF'
    } else if (this.category === "foreign") {
      this.title = "货币对";
      this.backgroundColor2 = '#798092';
      this.textColor2 = '#FFFFFF'
    } else if (this.category === "industry") {
      this.title = "行业/板块";
      this.backgroundColor2 = '#798092';
      this.textColor2 = '#FFFFFF'
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
