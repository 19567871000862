import Layout from "@/layout";

export const stockRouter = {

    path: '/stock',
    component: Layout,
    hidden: false,
    icon:'user',
    name:"股票",
    meta:{title: "股票", icon: 'menu-stock', menu: true},
    children: [
      {
        path: 'select',
        name: '选股',
        icon:'user',
        meta: {title: '选股', menu: true},
        component: {render: c => c("router-view")},
        children:[
          {
            path:"winner",
            name: 'winner',
            component: (resolve) => require(['@/views/stock/selector/stockDragonTiger'], resolve),
            meta: {menu: true, title: "龙虎榜"},
          },
          {
            path:"upLimitPool",
            name: 'upLimitPool',
            component: (resolve) => require(['@/views/stock/selector/stockMarketUpLimit.vue'], resolve),
            meta: {menu: true, title: "涨停榜"},
          },
          {
            path:"stockHot",
            name: 'stockHot',
            component: (resolve) => require(['@/views/stock/selector/stockHot'], resolve),
            meta: {menu: true, title: "热门榜"},
          }
        ]
      }, {
        path: 'stockDetail/:code',
        component: (resolve) => require(['@/views/stock/stockContainer.vue'], resolve),
        name: 'stockDetail',
        icon:'user',
        meta: {menu: false, title: "股票详情"}
      }, {
        path: 'panorama',
        component: (resolve) => require(['@/views/stock/panorama/stockPanorama.vue'], resolve),
        name: "panorama",
        icon:'user',
        meta: {menu: true, title: "大屏看盘"}
      },
      {
        path: 'overview',
        component: (resolve) => require(['@/views/stock/panorama/stockOverview.vue'], resolve),
        name: "stockOverview",
        icon:'user',
        meta: {menu: true, title: "全景看盘"}
      },
      // {
      //   path: 'stock/selector',
      //   component: (resolve) => require(['@/views/stock/detail/stockMain.vue'], resolve),
      //   name: '个人中心44',
      //   icon:'user',
      //   meta: {icon: 'user', menu: true, title: "股票详情"}
      // },
      {
        path: 'cn/stock/ipo/apply',
        component: (resolve) => require(['@/views/stock/selector/stockIpoApply'], resolve),
        name: 'ipoApply',
        icon:'user',
        meta: {title: "打新日历", menu: true}
      },
      // {
      //   path: 'newStock',
      //   component: (resolve) => require(['@/views/stock/detail/stockCompanyProfile.vue'], resolve),
      //   name: 'companyProfile',
      //   icon:'user',
      //   meta: {icon: 'user', title: "公司概况", menu: false}
      // }
    ]
}
