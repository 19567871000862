<template>
  <div :class="{'show':show}" class="header-search">
    <svg-icon class-name="search-icon" icon-class="search" @click.stop="click" />
    <el-select
      ref="headerSearchSelect"
      v-model="search"
      :remote-method="querySearch"
      filterable
      default-first-option
      remote
      placeholder="股票/代码/名称等"
      class="header-search-select"
      @change="change"
    >
      <el-option v-for="item in options" :key="item.id" :value="item">
          <span>{{item.name}}({{item.code}})</span>

          <indicator :category="item.category"></indicator>
      </el-option>
    </el-select>

  </div>
</template>

<script>
import Fuse from 'fuse.js/dist/fuse.min.js'
import {getCommonSearch} from "@/api/headerSearchApi"
import CodeIndicator from "@/components/CodeIndicator/index.vue";
import Indicator from "@/components/Indicator/index.vue";
import path from 'path'
import {TradeCategoryEnum} from "@/utils/constants";
export default {
  name: 'HeaderSearch',
  components:{
    CodeIndicator,Indicator
  },
  data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined
    }
  },
  watch: {
    searchPool(list) {
      this.initFuse(list)
    },
    show(value) {
      if (value) {
        document.body.addEventListener('click', this.close)
      } else {
        document.body.removeEventListener('click', this.close)
      }
    }
  },
  mounted() {
    // review
   // this.searchPool = this.generateRoutes(this.routes)
  },
  methods: {
    click() {
      this.show = !this.show
      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus()
      }
    },
    close() {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur()
      this.options = []
      this.show = false
    },
    change(val) {
      if (val.category === TradeCategoryEnum.STOCK.code) {
        this.$router.push({
          name: "stockDetail",
          params: {
            code: val.code
          }
        })
      } else if (val.category === TradeCategoryEnum.INDEX.code) {
        this.$router.push({
          name: "indexDetail",
          params: {
            code: val.code
          },
        })
      } else if (val.category === TradeCategoryEnum.FUND.code) {
        this.$router.push({
          name: "fundDetail",
          params: {
            code: val.code
          },
        })
      } else if (val.category === TradeCategoryEnum.FOREIGN.code) {
        this.$router.push({
          name: "foreignDetail",
          params: {
            code: val.code
          }
        })
      } else if (val.category === TradeCategoryEnum.STOCK_INDUSTRY.code) {
        this.$router.push({
          name: "industryDetail",
          params: {
            code: val.code
          }
        })
      }
      else {
        this.search = ''
        this.options = []
        this.$nextTick(() => {
          this.show = false
        })
      }
    },
    initFuse(list) {
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7
        }, {
          name: 'path',
          weight: 0.3
        }]
      })
    },

    querySearch(query) {
      if (query == null || query.length < 1) {
        return
      }
      getCommonSearch({"search" : query}).then(response => {
        this.options = response.data;
      })
    },

    ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.header-search {
  font-size: 0 !important;

  .search-icon {
    cursor: pointer;
    font-size: 18px;
    vertical-align: middle;
  }

  .header-search-select {
    font-size: 18px;
    transition: width 0.2s;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    width: 210px;
    margin-left: 10px;

     review
    :deep(.el-input__inner) {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      vertical-align: middle;
    }
  }

  &.show {
    .header-search-select {

    }
  }
}
</style>
