import Layout from "@/layout";

export const newsRouter = {
  path: '/real',
  component: Layout,
  hidden: false,
  children: [{
    path: '/news',
    component: (resolve) => require(['@/views/news/news'], resolve),
    name: 'news',
    icon:'user',
    meta: {menu: true, title: "实时新闻"}
  },
    {
      path: '/calendar',
      component: (resolve) => require(['@/views/news/calendar'], resolve),
      name: 'calendar',
      icon:'user',
      meta: {menu: true, title: "经济日历"}
    },
    // {
    // // 原接口不在更新
    //   path: '/daily',
    //   component: (resolve) => require(['@/views/news/dailyNews'], resolve),
    //   name: 'daily',
    //   icon:'user',
    //   meta: {menu: true, title: "每日精选"}
    // }
  ],
  icon:'user',
  name:"calendar",
  meta:{icon: 'menu-calendar', menu: true, title: "财经-新闻/日历"}
}
