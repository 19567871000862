import Layout from '@/layout'


export const industryRouter = {
  path: '/partition',
  component: Layout,
  name: "partition",
  meta: {title: '行业/板块', icon: 'menu-industry',  menu: true},
  children: [
    {
      path:"industry",
      name: '行业',
      meta: {menu: true, title: "行业"},
      component: {render: c => c("router-view")},
      children:[{
        path: 'list',
        component: (resolve) => require(['@/views/industry/industry/industryList.vue'], resolve),
        name: 'industry',
        meta: {title: '行业列表', menu: true}
      },
      {
        path: 'panorama',
        component: (resolve) => require(['@/views/industry/industry/industryPanorama.vue'], resolve),
        name: 'industry',
        meta: {title: '行业大屏',  menu: true}
      },
      ]},
    {
    path: 'concept',
    component: (resolve) => require(['@/views/industry/concept/conceptList.vue'], resolve),
    name: 'concept',
    meta: {title: '概念', menu: true}
  },{
    path: 'region',
    component: (resolve) => require(['@/views/industry/region/regionList.vue'], resolve),
    name: 'region',
    meta: {title: '地区', menu: true}
  },
    {
      path: 'detail/:code',
      component: (resolve) => require(['@/views/industry/detail/industryContainer.vue'], resolve),
      name: 'industryDetail',
      meta: {title: '行业详情', menu: false}
    },
  ]
}
