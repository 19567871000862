import Layout from "@/layout";

export const indexRouter = {
  path: '/stockIndex',
  component: Layout,
  hidden: false,
  children: [{
    path: 'primary',
    component: (resolve) => require(['@/views/index/primary/index.vue'], resolve),
    icon:'user',
    name: '大盘指数',
    meta: {menu: true, title: "大盘指数"}
  },
    {
      path: 'list',
      component: (resolve) => require(['@/views/index/list/index.vue'], resolve),
      name: '所有指数',
      icon:'user',
      meta: {menu: true, title: "指数列表"}
    },
    {
      path: 'buffett',
      component: (resolve) => require(['@/views/index/buffett/indexBuffett.vue'], resolve),
      name: '巴菲特指数',
      icon:'user',
      meta: {menu: true, title: "巴菲特指数"}
    },
    {
      path: 'indexDetail/:code',
      component: (resolve) => require(['@/views/index/detail/indexContainer.vue'], resolve),
      name: 'indexDetail',
      icon:'user',
      meta: {menu: false, title: "指数详情"}
    },
  ],
  icon:'user',
  name: "指数",
  meta:{icon: 'menu-index', menu: true, title: "指数"}
}
