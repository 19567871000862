import Layout from "@/layout";

export const cnMacroRouter = {
  path: '/cn-macro',
  component: Layout,
  hidden: false,
  icon:'user',
  name:"中国宏观",
  meta:{icon: 'user', menu: true, title: "中国宏观"},
  children: [{
    path: "/lever/ratio",
    name: 'leverRatio',
    meta: {menu: true, title: "国民杠杆率"},
    component: (resolve) => require(['@/views/cnMacro/macroLeverRatio'], resolve)
    },
    {
      path: "cn-pmi",
      name: 'cn-pmi',
      meta: {menu: true, title: "PMI"},
      component: (resolve) => require(['@/views/cnMacro/cnPMI'], resolve)
    },
    ]
  }
