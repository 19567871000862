<template>
  <div :class="{'has-logo':showLogo}" :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
        :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
      >
        <!-- 根据 sidebarRouters 路由，生成菜单 -->
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path  + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";
import {toCamelCase} from "@/utils";
import {constantRoutes} from "@/router";

export default {
  components: {SidebarItem, Logo},
  data() {
    return {
      sidebarRouters:[]
    }
  },
  methods: {

    filterRouter: function (route1, lastRouter = false, type = false) {
      // debugger;


      // this.route.path = route1;
      // window.location.
      var result = route1.filter(route => {
        // 将 ruoyi 后端原有耦合前端的逻辑，迁移到此处
        // 处理 meta 属性


        // route.meta = r
        // // 路由地址转首字母大写驼峰，作为路由名称，适配 keepAlive
        // route.name = toCamelCase(route.path, true)
        // // 处理三级及以上菜单路由缓存问题，将path名字赋值给name
        // if (route.path.indexOf("/") !== -1) {
        //   var pathArr = route.path.split("/")
        //   route.name = toCamelCase(pathArr[pathArr.length - 1], true)
        // }
        // route.hidden = !route.visible
        // 处理 component 属性
        // filterChildren
        if (route.children) {
          route.children = this.filterRouter(route.children)
        }
        if (route.meta == null || route.meta.menu == null || route.meta.menu == false) {

          return false;
        }
        // route.meta = {
        //   title: route.title,
        //   icon: route.icon,
        //   noCache: !route.keepAlive,
        // }
        // if (route.children != null && route.children && route.children.length) {
        //   route.children = this.filterRouter(route.children, route, type)
        // } else {
        //   delete route['children']
        // }
        return true;
      });
      // console.log("return" + JSON.parse(result));
        return result
    }
  },
  mounted() {
    let test = this.$router.options.routes;
    this.sidebarRouters = this.filterRouter(constantRoutes, false, false);
  },

  created() {
    console.log("created");
    // let menu = ;

    // function filterRouter($route) {
    //
    // }

  },

  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebar"]),
    activeMenu() {
      const route = this.$route;
      const {meta, path} = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },

    // sidebarRouters() {
    //   console.log(this.$router.options.routes)
    //   this.$route().options.routers.forEach()
    //   return ;
    // },

      /*
            return [
                {
                  "id": 1179,
                  "parentId": 1117,
                  "name": "Merchant",
                  "path": "merchant",
                  "component": () => import("@/views/pay/merchant"),
                  "icon": "merchant",
                  "visible": true,
                  "keepAlive": true,
                  "meta": {
                    "title": "商户信息",
                    "icon": "merchant",
                    "noCache": false
                  },
                  "hidden": false
                }, {
                  "id": 1126,
                  "parentId": 1117,
                  "name": "App",
                  "path": "merchant",
                  "component": () => import("@/views/pay/merchant"),
                  "icon": "table",
                  "visible": true,
                  "keepAlive": true,
                  "meta": {
                    "title": "应用信息",
                    "icon": "table",
                    "noCache": false
                  },
                  "hidden": false
                }, {
                  "id": 1173,
                  "parentId": 1117,
                  "name": "Order",
                  "path": "merchant",
                  "component": () => import("@/views/pay/merchant"),
                  "icon": "pay",
                  "visible": true,
                  "keepAlive": true,
                  "meta": {
                    "title": "支付订单",
                    "icon": "pay",
                    "noCache": false
                  },
                  "hidden": false
                }, {
                  "id": 1161,
                  "parentId": 1117,
                  "name": "Refund",
                  "path": "merchant",
                  "component": () => import("@/views/pay/merchant"),
                  "icon": "order",
                  "visible": true,
                  "keepAlive": true,
                  "meta": {
                    "title": "退款订单",
                    "icon": "order",
                    "noCache": false
                  },
                  "hidden": false
                }]

          }
        }
        */
  },


}


</script>
