import Layout from "@/layout";

export const foreignRouter = {
  path: '/foreign',
  component: Layout,
  hidden: false,
  icon:'user',
  name:"黄金/货币对",
  meta:{icon: 'menu-foreign', menu: true, title: "黄金/货币对"},
  children: [
    {
      path: "xau",
      name: 'xau',
      meta: {menu: true, title: "黄金"},
      component: (resolve) => require(['@/views/foreign/xau/xauList.vue'], resolve)
    },{
      path: "currency",
      name: 'currency',
      meta: {menu: true, title: "货币对"},
      component: (resolve) => require(['@/views/foreign/currency/currencyList.vue'], resolve)
    },
    {
      path: 'foreignDetail/:code',
      component: (resolve) => require(['@/views/foreign/foreignContainer.vue'], resolve),
      name: 'foreignDetail',
      icon:'user',
      meta: { menu: false, title: "货币对详情"}
    },
  ]
}
