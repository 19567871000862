import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
import {stockRouter} from "@/router/menurouter/stockRouter";
import {fundRouter} from "@/router/menurouter/fundRouter";
import {macroRouter} from "@/router/menurouter/macroRouter";
import {indicatorRouter} from "@/router/menurouter/indicatorRouter";
import {indexRouter} from "@/router/menurouter/indexRouter";
import {cnMacroRouter} from "@/router/menurouter/cnMacroRouter";
import {trendRouter} from "@/router/menurouter/trendRouter";
import {newsRouter} from "@/router/menurouter/newsRouter";
import {aboutRouter} from "@/router/menurouter/aboutRouter";
import {foreignRouter} from "@/router/menurouter/foreignRouter";
import {industryRouter} from "@/router/menurouter/industryRouter";

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 【重要】当设置 true 的时候该路由不会再侧边栏出现 如 401，login 等页面，或者如一些编辑页面 /edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * path: '/login',                // 【重要】访问的 URL 路径
 * component: Layout,             // 【重要】对应的组件；也可以是 (resolve) => require(['@/views/login'], resolve),
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 【重要】设定路由的名字，一定要填写不然使用 <keep-alive> 时会出现各种问题
 * meta : {
    noCache: true                // 【重要】如果设置为 true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 【重要】设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 【重要】设置该路由的图标，对应路径 src/assets/icons/svg
    breadcrumb: false            // 如果设置为 false，则不会在 breadcrumb 面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */


// 公共路由
export let constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login/login2'], resolve),
    hidden: true
  },
  {
    path: '/wechatLogin',
    component: (resolve) => require(['@/views/login/wechatLoginDirect.vue'], resolve),
    hidden: true
  },
  {
    path: '/wechatBind',
    component: (resolve) => require(['@/views/login/wechatBindDirect.vue'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  // {
  //   path: '/user',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [{
  //     path: 'profile',
  //     component: (resolve) => require(['@/views/user/profile/index'], resolve),
  //     name: 'Profile',
  //     meta: {title: '个人中心', icon: 'user'}
  //   }
  //   ]
  // },
  {
    path: '/user',
    component: Layout,
    children: [{
      path:"profile",
      component: (resolve) => require(['@/views/user/profile/index'], resolve),
      name: 'Profile',
      icon:'user',
      meta: {name: '个人中心', menu: true, title: "个人中心"}
    }]

  },
  {
    path: '/user',
    component: Layout,
    children: [{
      path:"partner",
      component: (resolve) => require(['@/views/user/partner/index'], resolve),
      name: 'partner',
      icon:'user',
      meta: {name: '个人中心', menu: true, title: "合作伙伴"}
    }]
  },

  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [{
        path: 'index',
        // component: (resolve) => require(['@/views/index'], resolve),
        component: (resolve) => require(['@/views/home/homeContainer.vue'], resolve),
        name: '首页',
        meta: {title: '首页', icon: 'dashboard', affix: true, menu: true}
      },
    ]
  },
  {
    path: '/home',
    component: Layout,
    meta: {title: '首页', icon: 'menu-home', menu: true},
    children: [{
      path:"",
      component: (resolve) => require(['@/views/home/homeContainer.vue'], resolve),
      name: 'home',
      icon:'user',
      meta: {name: '首页', menu: true, title: "首页"}
    }]
  },
  {
    path: '/favorite',
    component: Layout,
    name: 'favorite',
    icon:'user',
    meta: {title: '自选股', icon: 'menu-favorite', menu: true},
    children: [
      {
        path: 'favorite',
        component: (resolve) => require(['@/views/favorite/favoriteContainer.vue'], resolve),
        name: 'favorite',
        icon:'user',
        meta: {title: '自选股', menu: true, needLogin: true},
      }
    ],
  },{
    path: '/vip',
    component: Layout,
    children: [{
      path:"introduce",
      component: (resolve) => require(['@/views/vip/vipIntroduce.vue'], resolve),
      name: 'introduce',
      meta: {name: 'vip', menu: false, title: "vip"}
    }]
  },
]

export let icpRoutes = [
  {
    path: 'https://beian.miit.gov.cn/#/Integrated/index',
    component: Layout,
    name: 'ICP',
    icon:'user',
    meta: {title: '沪ICP备2023032559号-1', menu: true},
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

constantRoutes = constantRoutes.concat(
  trendRouter,
  stockRouter,
  indexRouter,
  fundRouter,
  industryRouter,
  // cnMacroRouter,
  foreignRouter,
  // macroRouter,
  newsRouter,
  aboutRouter,
  icpRoutes);
export default new Router({
  base: process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "/",
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({y: 0}),
  // constantRoutes.push(chinaIndex);
  routes: constantRoutes
})
