import Layout from "@/layout";

export const aboutRouter = {
  path: '/about',
  component: Layout,
  hidden: false,
  children: [{
    path: 'us',
    component: (resolve) => require(['@/views/about/us/aboutUs'], resolve),
    name: 'ChartGPT',
    icon:'user',
    meta: {menu: true, title: "关于我们"}
  }, {
      path: 'problem',
      component: (resolve) => require(['@/views/about/problem/problemContainer'], resolve),
      name: 'AI画画',
      icon:'user',
      meta: {menu: true, title: "问题/建议"}
  }, {
    component: (resolve) => require(['@/views/about/agreement/userAgreement.vue'], resolve),
    hidden: true,
    path: "user-agreement",
    // component: require(['@/views/about/agreement/userAgreement.vue']),

    icon:'user',
    meta: {menu: false, title: "用户协议"}

  },
    {
      component: (resolve) => require(['@/views/about/agreement/privacyPolicy.vue'], resolve),
      hidden: true,
      path: "privacy_policy",
      // component: require(['@/views/about/agreement/userAgreement.vue']),

      icon:'user',
      meta: {menu: false, title: "隐私政策"}

    }


  ],
  icon:'user',
  name:"联系我们",
  meta:{icon: 'menu-about', menu: true, title: "联系我们"}



}
