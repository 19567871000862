import router from '../router'
import store from '@/store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getAccessToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false })

// 增加三方登陆 update by 芋艿
// const whiteList = ['/login', '/social-login',  '/auth-redirect', '/bind', '/register', '/oauthLogin/gitee']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getAccessToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      // debugger;
      if (store.getters.name === "" || store.getters.name == null) {
        isRelogin.show = true
        // 判断当前用户是否已拉取完 user_info 信息
        store.dispatch('GetInfo').then(() => {
          isRelogin.show = false;
          next()
        }).catch(err => {
          store.dispatch('LogOut').then(() => {
            Message.error(err)
            console.log("logout next to router : /index")
            next({ path: '/index' })
          })
        })
      } else {
        next()
      }
    }
  }

  else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})
