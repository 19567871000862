import Layout from '@/layout'


export const indicatorRouter = {
  path: 'get',
    component: Layout,
  name: "趋势选股",
  meta: {title: '趋势选股', icon: 'dashboard',  menu: true},
  children: [{
    path: 'index',
    component: (resolve) => require(['@/views/index'], resolve),
    name: '我的历史趋势图',
    meta: {title: '我的历史趋势图', icon: 'dashboard', menu: true}
  },{
    path: 'index',
    component: (resolve) => require(['@/views/index'], resolve),
    name: '新增趋势图',
    meta: {title: '新增趋势图', icon: 'dashboard', menu: true}
  },
  ]
}
