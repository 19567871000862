import Layout from "@/layout";

export const fundRouter = {
  path: '/fund',
  component: Layout,
  hidden: false,
  children: [{
    path: 'detail/:code',
    component: (resolve) => require(['@/views/fund/fundContainer.vue'], resolve),
    name: 'fundDetail',
    icon:'user',
    meta: {menu: false, title: "基金详情"}
  },
    {
      path: 'boardList',
      component: (resolve) => require(['@/views/fund/board/fundBoard.vue'], resolve),
      name: 'fund',
      icon:'user',
      meta: {menu: true, title: "基金"}
    }
  ],
  icon:'user',
  name:"基金",
  meta:{icon: 'menu-fund', menu: true, title: "基金"}
}
