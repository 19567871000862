import Layout from "@/layout";

export const trendRouter = {

  path: '/trend',
  component: Layout,
  hidden: false,
  icon:'user',
  name:"智能选股",
  meta:{title: "智能选股", icon: 'aiIcon', menu: true},
  children: [
    {
      path: 'model',
      name: '我的模型',
      meta: {title: '智能选股', icon: 'aiIcon', menu: true},
      component: (resolve) => require(['@/views/trend/trendModelContainer.vue'], resolve)
    },
    {
      path: 'edit/:modelKey',
      name: "modelEdit",
      meta: {title: '编辑模版', icon: 'user', menu: false},
      component: (resolve) => require(['@/views/trend/trendModelEdit.vue'], resolve)
    },
    {
      path: 'detail/:modelKey',
      name: "modelDetail",
      meta: {title: '执行记录', icon: 'user', menu: false},
      component: (resolve) => require(['@/views/trend/trendModelDetail.vue'], resolve)
    },
  ]
}
