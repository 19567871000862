<template>
  <div>
    <div >indicator</div>
  </div>
</template>

<script>
export default {
  name: "CodeIndicator",
  props: {
    code : String
  },
  mounted() {
    console.log("indicator" + this.code);
  }
}
</script>

<style scoped>

</style>
