<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/>


    <div class="right-menu">

<!--      <el-popover-->
<!--        placement="top"-->
<!--        width="320"-->
<!--        v-model="visible"-->
<!--        :disabled="vipPopDisabled">-->
<!--        <p>新注册用户免费试用半个月的会员</p>-->
<!--        <div style="text-align: right; margin: 0">-->
<!--          <el-button size="mini" type="text" @click="onKnownVip()">我知道了</el-button>-->
<!--        </div>-->
        <el-image
          slot="reference"
          class="right-menu-item"
          style="width: 100px; height: 32px; cursor: pointer;"
          :src="vipEnable? defaultVipImageUrl : defaultUnVipImageUrl"
          @click="showVipRouter(1)"
          fit="contain"
        ></el-image>
<!--      </el-popover>-->
<!--      <div class="dialog_diy">-->

          <el-dialog width="520px" custom-class="new-dialog"  :visible.sync="visible" >
<!--        <div>-->
        <div slot="title" class="header-title">
          <el-image style="height: 470px; cursor:pointer"  :src="giveVipShowUrl" @click="onKnownVip()"> </el-image>
        </div>

      </el-dialog>
<!--      </div>-->


<!--      <template v-if="device!=='mobile'">-->
      <template>
        <search id="header-search" class="right-menu-item" />
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
      </template>

      <el-dropdown v-if="userName" class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <el-image :src="(avatar == null || avatar === '') ? defaultImageUrl : (baseURL + 'system/oss/image/' + avatar)" class="user-avatar"/>
<!--          <el-image :src="avatar == null ? '@/assets/images/profile.jpg': (baseURL + 'system/oss/image/' + avatar)" class="user-avatar"/>-->
          <span v-if="userName" class="user-userName">{{ userName }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/user/profile">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <router-link v-if="isPartner != null && isPartner === 1" to="/user/partner">
              <el-dropdown-item>合作伙伴</el-dropdown-item>
            </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-button style=" width: 72px; margin-right: 16px; text-align: center;vertical-align:middle;" v-else ghost >
        <router-link style="width: 100%; height: 100%"  to="/login">登录</router-link>
      </el-button>


<!--      <el-dialog :visible.sync="showImag" width="30%">-->
<!--        <el-image-->
<!--          style="width: 1440px; height: 380px;position:absolute;left:0px; top: 0px"-->
<!--          :src="require('@/assets/images/bg-about.png')"-->
<!--          fit="fill"-->
<!--        ></el-image>-->
<!--      </el-dialog>-->

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'

import {getAccessToken} from "@/utils/auth"
import ShowVipButton from "@/components/Vip/ShowVipButton.vue";
import {rgb} from "chalk";

export default {
  data(){
    return {
      isLogin : false,
      baseURL: process.env.VUE_APP_BASE_API,
      defaultImageUrl:require('@/assets/images/profile.jpg'),
      visible: false,
      vipPopDisabled:true,
      defaultVipImageUrl:require('@/assets/images/vip.png'),
      defaultUnVipImageUrl:require('@/assets/images/unvip.png'),
      giveVipShowUrl:require('@/assets/images/giveVip.png'),
      vipEndAt : null,
      showImag: true,
    }
  },
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    ShowVipButton,
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'userName',
      'device',
      'isPartner'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    },
    vipEnable: {
      get() {
        if (this.vipEndAt == null) {
          return false;
        }
        return  new Date(this.vipEndAt).getTime() - new Date().getTime() > 0;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vipEndAt = this.$store.state.user.vipEndAt;
    });

    this.isLogin = getAccessToken() != null;
    // setTimeout(this.visible = true, 10000)

    const knownVipTipStr = JSON.parse(localStorage.getItem('knownVipTip')) || '';
    if (knownVipTipStr == null || knownVipTipStr !== 1) {
      let createAt = this.$store.state.user.createAt;
      let date = new Date(createAt);
      if (date !== null) {
        let seconds = new Date().getTime() - date.getTime();
        if (seconds < 8 * 60 * 60 * 1000) {
          this.visible = true;
          this.vipPopDisabled = false;
        }
      }
    }
  },
  methods: {
    rgb,
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    showVipRouter() {
      this.$router.push({
        name: "introduce",
      })
    },
    onKnownVip() {
      debugger;
      this.visible = false;
      this.vipPopDisabled = true;
      this.$cache.local.set(
        "knownVipTip",
        `1`
      );
    },
    async logout() {
      this.$modal.confirm('确定注销并退出系统吗？', '提示').then(() => {
        this.$store.dispatch('LogOut').then(() => {
          // location.href = getPath('/index');
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="scss">
.new-dialog {
  border-radius: 16px;
  overflow:hidden;
  background: transparent !important;
}
.new-dialog .el-dialog__header {
  background: transparent !important;
  padding: 0 ;
  height: 470px;
  width: 540px;

}
.new-dialog .el-dialog__body {
  background-color: transparent !important;
  height: 0px;
  hidden: true;
  padding: 0 ;
}
.dialog_diy{
  ::v-deep .el-dialog__wrapper { // eslint-disable-line
    .el-dialog {
      .el-dialog__body{
        padding: 0px;
      }
      .el-dialog__header{
        padding: 0px;
      }
      .el-dialog__headerbtn {
        top: 5px;
        right: 5px;
        padding-top: 10px;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #000;
        height: 30px;
        width: 35px;
      }
      .el-dialog__headerbtn .el-dialog__close:hover {
        color: gray;
      }
    }
  }
}

//::v-deep .el-card__body {
//  background-color: #0000FF;
//  border: 0;
//}
//.el-dialog {
//  background-color: transparent !important;
//}
//.el-dialog{
//  width: 30%;
//  background-color: rgba(0,38,141,0.36) !important;
//  border: solid #77f8ef;
//}

.dialog ::v-deep .el-dialog__wrapper {
  background-color:rgba(0,0,0,0.1);
}
//.el-dialog__warpper {
//  background-color: rgba(0,0,0,0.5);
//}
.loginButton {
  display: inline-block;
  padding: 0 0px;
  height: 100%;
  font-size: 14px;
  color: #5a5e66;
  vertical-align: text-bottom;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;

  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: middle;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
        .user-userName{
          margin-left: 5px;
          font-size: 14px;
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
